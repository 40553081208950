
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as apiPOls7UCb_455RmeveG7IBS2Vk8bjVCXoUVLulTLppVAnAMeta } from "/app/pages/api.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyxyrJyjSeOpGN_D08QZsO5Csr7DLueZBtja09XVxe_45u0Meta } from "/app/pages/privacy.vue?macro=true";
import { default as _91showName_93jMTCaI5hkbZMdKQZw_45NfFdbtjAR1R4ZMFlPboNRCD3QMeta } from "/app/pages/shows/[showId]/[showName].vue?macro=true";
export default [
  {
    name: "Api",
    path: "/api",
    meta: apiPOls7UCb_455RmeveG7IBS2Vk8bjVCXoUVLulTLppVAnAMeta || {},
    component: () => import("/app/pages/api.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "Privacy Policy",
    path: "/privacy",
    meta: privacyxyrJyjSeOpGN_D08QZsO5Csr7DLueZBtja09XVxe_45u0Meta || {},
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "show-details",
    path: "/shows/:showId()/:showName()",
    meta: _91showName_93jMTCaI5hkbZMdKQZw_45NfFdbtjAR1R4ZMFlPboNRCD3QMeta || {},
    component: () => import("/app/pages/shows/[showId]/[showName].vue")
  }
]